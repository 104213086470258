import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"

function extractFromMeta ( name ) {
	return document.querySelector (`meta[name="${name}"]`)?.getAttribute ("content") || ""
}

export default function () {

	const title = extractFromMeta ("HEADER_TITLE")
	const subtitle = extractFromMeta ("HEADER_SUBTITLE")
	const buttonOneText = extractFromMeta ("BUTTON_ONE_TEXT")
	const buttonOneHref = extractFromMeta ("BUTTON_ONE_HREF")
	const buttonTwoText = extractFromMeta ("BUTTON_TWO_TEXT")
	const buttonTwoHref = extractFromMeta ("BUTTON_TWO_HREF")

	return <Box
		sx={{
			height: "100%",
			width: "100%",
			display: "flex",
			alignItems: { xs: "start", sm: "center" },
			pt: { xs: 5, sm: 0 },
			justifyContent: "center",
			color: "white",
		}} >
		<Stack spacing={3} sx={{ p: 2, maxWidth: 600 }} >
			<Typography
				variant="h4"
				align="left"
				color="inherit" >
				{title}
			</Typography>
			<Typography
				variant="h6"
				align="left"
				color="inherit"
				sx={{ opacity: 0.8 }} >
				{subtitle}
			</Typography>
			<Stack direction={{ xs: "column", sm: "row" }} spacing={2} >
				<Button
					fullWidth
					href={buttonOneHref}
					target="_blank"
					variant="outlined"
					size="large"
					color="inherit" >
					{buttonOneText}
				</Button>
				<Button
					fullWidth
					href={buttonTwoHref}
					target="_blank"
					variant="outlined"
					size="large"
					color="inherit" >
					{buttonTwoText}
				</Button>
			</Stack>
		</Stack>
	</Box>
}
