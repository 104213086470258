import React from "react"
import ReactDOM from "react-dom"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import Home from "./Home"
import theme from "./theme"

if ( process.env.NODE_ENV !== "production" ) {
	console.log ( "MUI Theme:", theme )
}

ReactDOM.render (
	<ThemeProvider theme={theme} >
		<CssBaseline/>
		<Home/>
	</ThemeProvider>,
	document.getElementById ("main")
)
