import "@fontsource/roboto"
import "@fontsource/poppins"
import { createTheme } from "@mui/material/styles"

export default createTheme ({
	typography: {
		fontFamily: "Roboto",
		fontSize: 14,
		h1: {
			fontFamily: "Poppins",
		},
		h2: {
			fontFamily: "Poppins",
		},
		h3: {
			fontFamily: "Poppins",
		},
		h4: {
			fontFamily: "Poppins",
		},
		h5: {
			fontFamily: "Poppins",
		},
		h6: {
			fontFamily: "Poppins",
		},
	},
	palette: {
		primary: {
			main: "#3B70DE",
		},
		secondary: {
			main: "#FFF6E8"
		},
		background: {
			default: "#1184D1"
		},
		divider: "#000000"
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					height: "100%",
					width: "100%",
				},
				body: {
					margin: 0,
					height: "100%",
					width: "100%",
				},
				"#main": {
					height: "100%",
					width: "100%",
				},
				"::selection": {
					background: "#89BA40",
					color: "#000000",
				},
			},
		},
	},
})
